import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

const useFingerprint = (): [string, any] => {
  const [fingerprint, setFingerprint] = useState("");
  const [device, setDevice] = useState({});

  useEffect(() => {
    const getCanvasFingerprint = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      ctx.textBaseline = "top";
      ctx.font = "16px Arial";
      ctx.fillStyle = "#f60";
      ctx.fillRect(125, 1, 62, 20);
      ctx.fillStyle = "#069";
      ctx.fillText("Hello, world!", 2, 15);
      ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
      ctx.fillText("Hello, world!", 4, 17);
      return canvas.toDataURL();
    };

    const getUserAgent = () => navigator.userAgent;
    const getLanguage = () => navigator.language;
    const getScreenResolution = () =>
      `${window.screen.width}x${window.screen.height}`;
    const getTimezone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
    const getPlugins = () =>
      Array.from(navigator.plugins)
        .map((plugin) => plugin.name)
        .join(",");
    const getWebGLFingerprint = () => {
      try {
        const canvas = document.createElement("canvas");
        const gl: any =
          canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
        const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");
        const vendor = gl.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL);
        const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        return `${vendor}###${renderer}`;
      } catch (e) {
        return "unknown";
      }
    };
    const getHardwareConcurrency = () =>
      navigator.hardwareConcurrency || "unknown";
    const getDeviceMemory = () => {
      const userNavigation: any = navigator;

      return userNavigation?.deviceMemory || "unknown";
    };
    const getColorDepth = () => window.screen.colorDepth;
    const getTouchSupport = () => (navigator.maxTouchPoints > 0 ? "yes" : "no");
    const getCpuClass = () => {
      const userNavigation: any = navigator;
      return userNavigation.cpuClass || "unknown";
    };
    const getPlatform = () => navigator.platform;

    const getInstalledFonts = () => {
      const fonts = ["Arial", "Verdana", "Times New Roman", "Courier New"];
      const testString = "mmmmmmmmmmlli";
      const testSize = "72px";
      const h = document.getElementsByTagName("body")[0];
      const baseFonts = ["monospace", "sans-serif", "serif"];
      const detectedFonts = [];

      fonts.forEach((font) => {
        baseFonts.forEach((baseFont) => {
          const span = document.createElement("span");
          span.style.fontSize = testSize;
          span.innerHTML = testString;
          span.style.fontFamily = baseFont;
          h.appendChild(span);
          const width = span.offsetWidth;
          const height = span.offsetHeight;
          span.style.fontFamily = `${font},${baseFont}`;
          if (span.offsetWidth !== width || span.offsetHeight !== height) {
            detectedFonts.push(font);
          }
          h.removeChild(span);
        });
      });
      return detectedFonts.join(",");
    };

    const getAudioFingerprint = async () => {
      try {
        const w: any = window;
        const audioContext = new (window.AudioContext ||
          w?.webkitAudioContext)();
        const oscillator = audioContext.createOscillator();
        const analyser = audioContext.createAnalyser();
        const gain = audioContext.createGain();
        const scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);
        const { destination } = audioContext;

        gain.gain.value = 0;
        oscillator.type = "triangle";
        oscillator.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(gain);
        gain.connect(destination);

        oscillator.start(0);

        const fingerprintPromise = new Promise((resolve) => {
          scriptProcessor.onaudioprocess = () => {
            const fingerprintArray = new Float32Array(
              analyser.frequencyBinCount
            );
            analyser.getFloatFrequencyData(fingerprintArray);
            resolve(fingerprintArray.join(","));
            oscillator.disconnect();
            scriptProcessor.disconnect();
            gain.disconnect();
            audioContext.close();
          };
        });

        return await fingerprintPromise;
      } catch (e) {
        return "unknown";
      }
    };

    const getFingerprint = async () => {
      const canvasFingerprint = getCanvasFingerprint();
      const audioFingerprint = await getAudioFingerprint();
      const userAgent = getUserAgent();
      const language = getLanguage();
      const screenResolution = getScreenResolution();
      const timezone = getTimezone();
      const plugins = getPlugins();
      const webGLFingerprint = getWebGLFingerprint();
      const hardwareConcurrency = getHardwareConcurrency();
      const deviceMemory = getDeviceMemory();
      const colorDepth = getColorDepth();
      const touchSupport = getTouchSupport();
      const cpuClass = getCpuClass();
      const platform = getPlatform();
      const installedFonts = getInstalledFonts();

      const fingerprintResult = [
        canvasFingerprint,
        userAgent,
        language,
        screenResolution,
        timezone,
        plugins,
        webGLFingerprint,
        hardwareConcurrency,
        deviceMemory,
        colorDepth,
        touchSupport,
        cpuClass,
        platform,
        installedFonts,
        audioFingerprint,
      ].join("###");

      const hash = CryptoJS.SHA256(fingerprintResult).toString();

      setDevice({
        canvasFingerprint,
        userAgent,
        language,
        screenResolution,
        timezone,
        plugins,
        webGLFingerprint,
        hardwareConcurrency,
        deviceMemory,
        colorDepth,
        touchSupport,
        cpuClass,
        platform,
        installedFonts,
        audioFingerprint,
      });

      return hash;
    };

    getFingerprint().then((fp) => setFingerprint(fp));
  }, []);

  return [fingerprint, device];
};

export default useFingerprint;
