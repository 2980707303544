import { useState, useCallback } from "react";
import { Toast } from "@shopify/polaris";

export default function useToast(): [
  any,
  (msg?: string, error?: boolean) => void
] {
  const [active, setActive] = useState(false);
  const [isError, setIsError] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(
    "Message sent"
  );

  const toggleActive = useCallback(
    (message?: string, errorEnabled?: boolean) => {
      if (errorEnabled) {
        setIsError(true);
      } else {
        setIsError(false);
      }
      setNotificationMessage(message);
      setActive((value) => !value);
    },
    [notificationMessage, isError]
  );

  if (!active) {
    return [null, toggleActive];
  }

  const toastMarkup = (
    <Toast
      content={notificationMessage}
      onDismiss={toggleActive}
      error={isError}
    />
  );

  return [toastMarkup, toggleActive];
}
