import { useSelector } from "react-redux";
import isEmpty from "lodash.isempty";
import moment from "moment";

import { RootState } from "@Components";
import { IUser } from "@Types";
import { getFirstLanguage } from "@Utils/formatUtils";

const { NEXT_PUBLIC_NODE_ENV } = process.env;

export const useUser = () =>
  useSelector<RootState, IUser>((state) => state?.user);

export const useUsers = () =>
  useSelector<RootState, IUser[]>((state) => state?.users || []);

export const useIsDev = () =>
  useSelector<RootState, boolean>(() => NEXT_PUBLIC_NODE_ENV === "development");

export const useUserEmail = () =>
  useSelector<RootState, string>((state) => state?.user?.email);

export const useUserSinceDays = () =>
  useSelector<RootState, number>((state) => {
    const user = state?.user;
    const sinceDays = moment().diff(user?.createdAt, "days");
    return sinceDays;
  });

export const useIsOnboardingCompleted = () =>
  useSelector<RootState, boolean>(
    (state) => state?.user?.isOnboardingCompleted
  );

export const useIsMonCashWithdraw = () =>
  useSelector<RootState, boolean>(
    (state) => state?.globalSetting?.isMonCashAutomaticWithdrawEnabled
  );

export const useIsComplyCubeIdentityEnabled = () =>
  useSelector<RootState, boolean>(
    (state) =>
      state?.user?.isComplyCubeIdentityEnabled &&
      state?.globalSetting?.isComplyCubeIdentityEnabled
  );

export const useIsDivvyCardEnabled = () =>
  useSelector<RootState, boolean>(
    (state) =>
      state?.globalSetting?.isDivvyCardEnabled &&
      isEmpty(state.user?.divvyCardHolderId)
  );

export const useIsFlutterWaveCreditCardEnabled = () =>
  useSelector<RootState, boolean>(() => false);

export const useUserLanguage = () =>
  useSelector<RootState, string>((state) =>
    getFirstLanguage(state?.user?.language || "en")
  );

export const useIsInit = () =>
  useSelector<RootState, boolean>((state) => state?.user?.isInit);

export const useIsTPCardMasterCardEnabled = () =>
  useSelector<RootState, boolean>(
    (state) => state?.globalSetting?.isTPMasterCardEnabled
  );

export const useIsBalanceEnabled = () =>
  useSelector<RootState, boolean>(
    (state) => state?.globalSetting?.isBalanceEnabled
  );

export const useIsPlaidEnabled = () =>
  useSelector<RootState, boolean>(
    (state) =>
      state?.globalSetting?.isPlaidEnabled && state.user?.isPlaidEnabled
  );

export const useIsStripeCreditCardEnabled = () =>
  useSelector<RootState, boolean>(
    (state) => state?.globalSetting?.isStripeCreditCardEnabled
  );

export const useIsAdmin = () =>
  useSelector<RootState, boolean>((state) => state?.user?.isAdmin);

export const useIsSuperAdmin = () =>
  useSelector<RootState, boolean>((state) => state?.user?.isSuperAdmin);

export const useIsLocked = () =>
  useSelector<RootState, boolean>((state) => state?.user?.isLocked);

export const useIsAgent = () =>
  useSelector<RootState, boolean>((state) => state?.user?.isAgent);

export const useUserID = () =>
  useSelector<RootState, string>(
    (state) => state?.user?.id || state?.user?.userID
  );

export const useIsVerified = () =>
  useSelector<RootState, boolean>(
    (state) =>
      state.globalSetting.isComplyCubeIdentityEnabled &&
      state.user?.isComplyCubeVerified
  );

export const useIsPhoneVerified = () =>
  useSelector<RootState, boolean>((state) => state?.user?.isPhoneVerified);

export const useIsStripeEnabled = () =>
  useSelector<RootState, boolean>((state) => state?.user?.isStripeEnabled);

export const useIsStripeIssuingEnabled = () =>
  useSelector<RootState, boolean>(
    (state) => state?.globalSetting?.isStripeIssuingEnabled
  );

export const useIsWithDrawEnabled = () =>
  useSelector<RootState, boolean>((state) => state?.user?.isWithdrawEnabled);

export const useIsMonCashEnabled = () =>
  useSelector<RootState, boolean>(
    (state) => state?.globalSetting?.isMonCashEnabled
  );

export const useIsCashAppEnabled = () =>
  useSelector<RootState, boolean>((state) => state?.user?.isCashAppEnabled);

export const useCardId = () =>
  useSelector<RootState, string>((state) => state?.user?.cardId);

export const useCardHolderId = () =>
  useSelector<RootState, string>((state) => state?.user?.cardHolderId);

export const useIsLongerThanSevenDays = () =>
  useSelector<RootState, boolean>((state) => {
    const createdAt = state?.user?.createdAt;
    const singByDays = moment().diff(createdAt, "days");

    const isLongerThanSevenDays = singByDays > 7;

    return isLongerThanSevenDays;
  });

export const useIsLongerThanTenDays = () =>
  useSelector<RootState, boolean>((state) => {
    const createdAt = state?.user?.createdAt;
    const singByDays = moment().diff(createdAt, "days");

    const isLongerThanSevenDays = singByDays >= 10;

    return isLongerThanSevenDays;
  });

export const useIsLongerThanTwoDays = () =>
  useSelector<RootState, boolean>((state) => {
    const createdAt = state?.user?.createdAt;
    const singByDays = moment().diff(createdAt, "days");

    const isLongerThanSevenDays = singByDays >= 2;

    return isLongerThanSevenDays;
  });

export const useIsLongerThanFourteenDays = () =>
  useSelector<RootState, boolean>((state) => {
    const createdAt = state?.user?.createdAt;
    const singByDays = moment().diff(createdAt, "days");

    const isLongerThanSevenDays = singByDays >= 14;

    return isLongerThanSevenDays;
  });
